import AlertContainer from "@/components/base/AlertContainer.vue";
import Button from "@/components/base/Button.vue";
import CardContainer from "@/components/base/CardContainer.vue";
import CollapsedAccordionContainer from "@/components/base/CollapsedAccordionContainer.vue";
import ContainerFluid from "@/components/base/ContainerFluid.vue";
import Loading from "@/components/base/Loading.vue";
import Modal from "@/components/base/Modal.vue";
import NonDatatableContainer from "@/components/base/NonDatatableContainer.vue";
import Notification from "@/components/base/Notification.vue";
import SplitColumnContainers from "@/components/base/SplitColumnContainers.vue";
import TableContainer from "@/components/base/TableContainer.vue";
import InPageAlert from "@/components/base/InPageAlert.vue";
export default (app) => {
    app.component('AlertContainer', AlertContainer)
    app.component('Button',Button)
    app.component('CardContainer', CardContainer)
    app.component('CollapsedAccordionContainer',CollapsedAccordionContainer)
    app.component('ContainerFluid', ContainerFluid)
    app.component('Loading',Loading)
    app.component('Modal',Modal)
    app.component('NonDatatableContainer', NonDatatableContainer)
    app.component('Notification', Notification)
    app.component('SplitColumnContainers', SplitColumnContainers)
    app.component('TableContainer', TableContainer)
    app.component('InPageAlert', InPageAlert)

    return app
}

