import {onMounted} from "vue";

export function sidebarSublinkCollapse() {
    // NOTE: Temporary solution to collapse accordions when non accordion links are clicked
    // No animation still
    // TODO: Refactor
    onMounted(() => {
        const accordion_links = document.querySelectorAll('div.accordion-item');
        const collapsed_children = document.querySelectorAll('div.collapsed-child');
        const none_accordion = document.querySelectorAll('.non-accordion a');
        // O(n^2)

        none_accordion.forEach((link)=> { // O(n)
            link.addEventListener('click', function (){ // O(1)
                collapsed_children.forEach((collapsed_child) => { // O(n)
                    collapsed_child.classList.remove('show')
                })
                accordion_links.forEach((accordion_link) => { // O(n)
                    accordion_link.setAttribute('aria-expanded', 'false')
                })
            })
        })
    })
}