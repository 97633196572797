import {defineStore} from "pinia";
import {onUnmounted, ref, watch} from "vue";
import {onBeforeRouteLeave, onBeforeRouteUpdate} from "vue-router";

// responsible for altering state of collapsibles
export const useCollapsibleStore = defineStore("collapsible",() => {
    const isSidebarCollapsed = ref(false)
    const isSideColumnCollapsed = ref(false)
    const modal = ref(null)
    const showForm = ref(false)
    const fileModal = ref(null)
    const popover = ref(null)
    // @params: component to collapse
    function collapseComponent(component) {
        if(component === 'sidebar') {
            isSidebarCollapsed.value = !isSidebarCollapsed.value
        } else if (component === 'sideColumn') {
            isSideColumnCollapsed.value = !isSideColumnCollapsed.value
        }
    }

    /** Function that sets whether to show the form in modal or in the side column **/
    function formContainer() {
        if(modal.value) {
            let form_mediaquery = window.matchMedia("(width < 1024px)")
            // w/matcher for media_query change
            form_mediaquery.onchange = () => {
                if(modal.value) {
                    //from bigger to smaller screen, if side column is open, show modal
                    if(isSideColumnCollapsed.value) {
                        modal.value.show()
                        isSideColumnCollapsed.value = false
                    }
                    //from smaller to bigger screen, hide modal but show form in side column
                    if($('#main-modal').is(':visible')) isSideColumnCollapsed.value = true
                    modal.value.hide()
                } else return
            }

            // in smaller device and add btn or table row is click, this will be triggered
            if(form_mediaquery.matches) modal.value.show()
            else isSideColumnCollapsed.value = true
        }
    }

    function modalEvents(event) {
        if(event === 'show') {
            if(fileModal.value) {
                fileModal.value.show()
            }
        }

        if(event === 'hide') {
            if(fileModal.value) {
                fileModal.value.hide()
            }
        }
    }

    function popoverEvent(event) {
        if(event) {
            if(popover.value) {
                popover.value.show()
            }
        } else {
            if(popover.value) {
                popover.value.hide()
            }
        }
    }

    onBeforeRouteUpdate(() => {
        isSideColumnCollapsed.value = false
    })

    onBeforeRouteLeave(() => {
        isSideColumnCollapsed.value = false
    })

    let bigger_to_smaller_width = window.matchMedia("(width >= 1024px)")
    const mediaquery = window.matchMedia("(width < 1024px)");
    mediaquery.onchange = (e) => {
        if(mediaquery.matches) showForm.value = true
        else showForm.value = false
        isSidebarCollapsed.value = false;
    }

    return {
        collapseComponent,
        isSidebarCollapsed,
        isSideColumnCollapsed,
        mediaquery,
        bigger_to_smaller_width,
        modal,
        formContainer,
        showForm,
        fileModal,
        modalEvents,
        popover,
        popoverEvent
    }
})
