<script setup>

</script>

<template>
<div class="sub-item-container">
  <slot name="nav-item"></slot>
</div>
</template>

<style scoped>

</style>