<script setup>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-dt';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-select-bs5';
import 'datatables.net-responsive-dt';

DataTable.use(DataTablesCore);

// must have function to accept data to pass to the datatable
const props = defineProps(['columns', 'data', "tableId"])
</script>

<template>
<div class="datatable-container px-xs-4 overflow-x-auto">
    <DataTable
        :columns="columns"
        :data="data"
        :id="tableId"
        :style="{ cursor: 'pointer' }"
        class="table table-bordered display sticky-header"
        style="font-family: var(--app-data-font-family);">
        <slot name="tableHeader"></slot>
    </DataTable>
</div>
</template>

<style scoped>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>
