import {ref} from "vue";
import {ERROR_FIELD, INITIAL_FIELD, SUCCESS_FIELD} from "@/constants";

/**
 * @param {Object} object the object to be validated
 * @param {Object} keysWithValidations the keys to be validated in the object if the key is not present then it will proceed to validate only if there are any additional validations supplied and that key has inputted value.
 * @returns {{validationObject: Ref<UnwrapRef<{}>>, validator: (function(*): void), resetValidation: (function(): void), checkForErrors: (function(): (boolean))}}
 */
export default function useValidator (object, keysWithValidations = {}) {
  const validationObject = ref({});

  for (let key of Object.keys(object)) {
    validationObject.value[key] = {
      message: "",
      fieldStatus: INITIAL_FIELD
    }
  }

  const emptyFieldValidator = (key) => {
    if (Array.isArray(object[key]) && object[key]?.length === 0 && keysWithValidations[key]?.required) {
      if(key === 'username' || key === 'password') {
        return {
          status: false,
          message : "This field cannot be empty."
        };
      }
      return {
        status: false,
        message: "This is a required field!"
      };
    } else if (typeof object[key] === 'string' && !object[key].trim().length) {
      return {
        status: false,
        message: "This is a required field!"
      };
    } else if (((object[key] === '' && typeof object[key] === 'string') || Object.is(object[key], null)|| (object[key] === 0 && typeof object[key] === 'number'))
      && keysWithValidations[key]?.required) {
      if(key === 'username' || key === 'password') {
        return {
          status: false,
          message : "This field cannot be empty."
        };
      }
      return {
        status: false,
        message: "This is a required field!"
      };
    }

    return {
      status: true,
      message: ""
    };
  }

  const setValidationStatus = (key, message, status) => {
    validationObject.value[key].fieldStatus = status;
    validationObject.value[key].message = message;
  }

  /**
   * validates the given key on the object. Upon validation, the field status and its message will change depending on the given validations
   * @param key
   */
  const validator = (key) => {
    let result;
    const validations = keysWithValidations?.[key]?.validations === undefined ? [] : keysWithValidations[key].validations;

    if (keysWithValidations[key]?.required) {
      result = emptyFieldValidator(key);
      if (!result.status) {
        setValidationStatus(key, result.message, ERROR_FIELD);
        return;
      }
    }

    if (object[key]) {
      for (let validation of validations) {
        result = validation(object[key]);
        if (!result.status) {
          setValidationStatus(key, result.message, ERROR_FIELD);
          return;
        }
      }
    }

    setValidationStatus(key, "", SUCCESS_FIELD);
  }

  /**
   * @param requiredOnly - if this is set to true, validate required fields only
   * @returns {boolean} - false if there is error. otherwise true for no errors
   */
  const checkForErrors = (requiredOnly = false) => {
    let keys = Object.keys(object);
    if (requiredOnly) keys = keys.filter(key => keysWithValidations[key]?.required);

    for (const key of keys) validator(key);

    for (const key of Object.keys(validationObject.value)) {
      if (validationObject.value[key].fieldStatus === ERROR_FIELD) return false;
    }

    return true;
  }

  /**
   * resets the validation object to their initial state
   */
  const resetValidation = () => {
    for (const key of Object.keys(validationObject.value)) {
      validationObject.value[key] = {
        message: "",
        fieldStatus: INITIAL_FIELD
      }
    }
  }

  return {
    validationObject,
    validator,
    resetValidation,
    checkForErrors
  }
}
