//note make ..env file and create VUE_APP_API_URL
export const API_URL = process.env.VUE_APP_API_URL;
export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const TOKEN = "FUJI_ACCTG_TOKEN";

export const ERROR_FIELD = 2;
export const INITIAL_FIELD = 0;
export const SUCCESS_FIELD = 1;

export const FIELD_STATUS_COLORS = {
    0: "",
    1: "isValidStyle",
    2: "isInvalidStyle"
}

export const VENDOR_CUSTOMER_FILE_TYPES = ["application/pdf"]

export const INPUT_TYPES = [
    'text',
    'number',
    'checkbox',
    'multiselect',
    'dropdown',
    'email',
    'radio',
    'file',
    'date',
    'datetime-local',
    // 'image',
    'month',
    'year',
    'color',
    'password',
    // 'range',
    // 'reset',
    // 'search',
    'tel',
    // 'time',
    'url',
    'week',
]

export const DATA_TYPES = [
    'string', 'integer', 'decimal', 'currency', 'Date', 'File', 'boolean'
]
