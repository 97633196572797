<script setup>
import Button from "@/components/base/Button.vue";
import {useCollapsibleStore} from "@/stores/useCollapsibleStore";
const collapsibleState = useCollapsibleStore()

const props = defineProps(['alertHeader','proceedBtn', 'closeBtn', 'form'])
</script>

<template>
  <Teleport to="body">
    <div id="main-alert" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center py-2 px-2">
          <div class="modal-header text-start px-4 pb-2">
            {{ alertHeader }}
          </div>
          <div class="modal-body px-4 py-2 text-start">
            <slot name="alertBody"></slot>
          </div>
          <div class="modal-footer">
            <Button v-if="closeBtn" style="background-color: var(--app-light-gray)" data-bs-dismiss="modal">Don't close</Button>
            <Button v-if="proceedBtn" class="btn-primary" data-bs-dismiss="modal" :form="form" type="reset" @click="collapsibleState.isSideColumnCollapsed = false">
              Proceed to close.
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>

.modal-content {
  border-radius: 3px !important;
  padding: .5rem !important;
}
.modal-header {
  display: block !important;
  border: none;
}
.show {
  animation: pulse .3s;
}
@keyframes pulse {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
</style>