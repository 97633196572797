<script setup>
import Header from '@/components/layout/Header.vue'
import Navbar from '@/components/layout/Navbar.vue'
import Sidebar from '@/components/base/Sidebar.vue'
import ContainerFluid from '@/components/base/ContainerFluid.vue'
import ManagementLinks from "@/components/sidebar-links/ManagementLinks.vue";
import TransactionsLinks from "@/components/sidebar-links/TransactionsLinks.vue";
import MonitoringLinks from "@/components/sidebar-links/MonitoringLinks.vue";
import ReportsLinks from "@/components/sidebar-links/ReportsLinks.vue";
import SetupLinks from "@/components/sidebar-links/SetupLinks.vue";

import {useCollapsibleStore} from "@/stores/useCollapsibleStore";

// when dynamically getting logo, change url or get from db
import Image from '@/assets/ftlp-logo-1.png'
import DashboardLinks from "@/components/sidebar-links/DashboardLinks.vue";
import {sidebarSublinkCollapse} from "@/stores/subSidebarLinkCollapse";

sidebarSublinkCollapse()
const collapsibleState = useCollapsibleStore()
</script>

<template>
  <div id="dashboard">
    <header id="master-header" class="sticky-top">
        <Header :logo="Image"/>
        <Navbar />
    </header>

    <ContainerFluid>
      <template #containerContent>
        <div class="row g-0">

          <div class="left-main-view z-3" :class="{'collapse-container' : collapsibleState.isSidebarCollapsed}">
            <Sidebar #sidebarContent :class="[ collapsibleState.isSidebarCollapsed ? 'sidebarOpened' : '' ]">
              <!--On setting the content of the sidebar based on the active route,
                  check if the container of parent route is or has child that is the active route.
                  The hiding of non-active routes is done through css
              -->
              <DashboardLinks />
              <TransactionsLinks />
              <ManagementLinks />
              <ReportsLinks />
              <MonitoringLinks />
              <SetupLinks />
            </Sidebar>
          </div>
          <div class="right-main-view">
            <RouterView class="px-2 pt-3"/>
          </div>
        </div>
      </template>
    </ContainerFluid>
  </div>
</template>

<style scoped>

</style>