import {defineStore} from "pinia";
import {ref} from "vue";

export const useModuleStatesStore = defineStore('module_state', () => {
    // states when loading, etc.
    const isLoading = ref(false)
    const isFilled = ref(true)
    const mode = ref('add')
    const showInPageAlert = () => {
        isFilled.value = false
        setTimeout(() => {
            isFilled.value = true;
        }, 5000)
    }
    return {
        isLoading,
        isFilled,
        mode,
        showInPageAlert
    }

})