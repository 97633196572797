<script setup>

</script>

<template>
  <div class="split-main-container">
    <div class="left-side">
      <slot name="leftSide"></slot>
    </div>
    <div class="right-side">
      <slot name="rightSide"></slot>
    </div>
  </div>
</template>

<style scoped>

</style>