<script setup>
import Button from "@/components/base/Button.vue";
import {useCollapsibleStore} from "@/stores/useCollapsibleStore";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const props = defineProps(['cardTitle', 'isCancelBtn', 'isSaveBtn', 'form', 'isEditBtn', 'isCloseBtn', 'isResetBtn', 'isDeleteBtn'])
const collapsibleState = useCollapsibleStore()
</script>

<template>
  <div id="main-card" class="card card-container" :class="{ 'cardToggled' : collapsibleState.isSideColumnCollapsed }">
    <div class="card-header">
      {{ cardTitle }}
    </div>
    <div class="card-body">
      <div class="px-2">
        <slot name="cardBody"></slot>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-end">
        <slot name="cardFooter"></slot>
        <Button type="button" v-if="isResetBtn" :form="form" class="btn btn-warning save-btn m-1" @click="$emit('resetAction', $event.target.blur())" @keyup.enter.prevent="$event.target.blur()"> Reset </Button>
        <Button type="button" v-if="isDeleteBtn" :form="form" class="btn btn-dark m-1" @click="$emit('deleteAction', $event.target.blur())" @keyup.enter.prevent="$event.target.blur()"> Delete </Button>
        <Button v-if="isSaveBtn" type="submit" :form="form" class="btn btn-success save-btn m-1" @click="$emit('saveAction', $event.target.blur())" @keyup.enter.prevent="$event.target.blur()">
          <font-awesome-icon icon="floppy-disk"></font-awesome-icon> Save </Button>
        <Button v-if="isEditBtn" type="submit" :form="form" class="btn btn-primary save-btn m-1" @click="$emit('saveAction', $event.target.blur())" @keyup.enter.prevent="$event.target.blur()"> Update </Button>
        <Button type="button" v-if="isCancelBtn" :form="form" class="btn btn-outline-dark m-1" @click="$emit('cancelAction', $event.target.blur())" @keyup.enter.prevent="$event.target.blur()"> Cancel </Button>
        <Button type = "button" v-if="isCloseBtn" :form="form" class="btn btn-outline-dark m-1" @click="collapsibleState.isSideColumnCollapsed = false"> Close </Button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  padding: 10px 5px !important;
}
</style>
