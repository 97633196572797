<script setup>

</script>

<template>
  <button class="btn">
    <slot></slot>
  </button>
</template>

<style scoped>

</style>