<script setup>
import ContainerFluid from '@/components/base/ContainerFluid.vue'
import UserProfile from '@/components/layout/UserProfile.vue'
import {TOKEN} from "@/constants";
import logo_no_title from '@/assets/logo.png'
import company_name from '@/assets/company-name.png'

const props = defineProps(['logo'])
let systemUser;

if(localStorage.getItem(TOKEN)) {
  systemUser = JSON.parse(localStorage.getItem('authUser')).first_name + ' ' + JSON.parse(localStorage.getItem('authUser')).last_name
}
</script>

<template>
    <ContainerFluid class="px-3 py-1" style="background-color: var(--header-bg); height: 2.9rem">
        <template #containerContent>
            <div class="d-flex justify-content-between">
              <RouterLink :to="{name : 'dashboard'}" class="d-flex align-items-center">
                <div class="logo pe-3">
                  <div class="only-logo">
                    <img :src="logo_no_title" alt="company-logo">
                  </div>
                  <div class="w-name">
                    <img class="full-logo" :src="logo" alt="company-logo">
                    <img class="company-name" :src="company_name" alt="company-name">
                    <span class="sys-type ps-md-2">ACCOUNTING SYSTEM</span>
                  </div>

                </div>
              </RouterLink>
              <div class="header-right-side d-flex">
                <div class="user">
                  <UserProfile :username="systemUser"/>
                </div>
              </div>
            </div>
        </template>
    </ContainerFluid>
</template>

<style scoped>
img { height: 20px; aspect-ratio: 2; }
.company-name {
  height: 20px;
  width: 110px;
  aspect-ratio: 1;
}
.only-logo img { height: 40px; aspect-ratio: 1; }
.sys-type {
  font-family: 'Inter Variable';
  font-size: .8rem;
  font-weight: 600;
  color: rgba(65, 84, 115)
}
a { text-decoration: none !important; }
.full-logo { display: none; }

.logo{
  border-right: 2px solid rgba(65, 84, 115, 0.3);
  display: flex;
  gap: 5px;
}

.w-name {
  display: flex;
  flex-direction: column;
  align-items: start;
}

@media (width >= 768px) {
  .w-name {
    flex-direction: row;
    align-items: center;
  }
  .full-logo { display: flex; }
  .logo{ display: block; border-right: 0;}
  .w-name img {
    height: 25px;
    aspect-ratio: 0;
  }
  .sys-type { font-size: .95rem; }
  .only-logo, .company-name{ display: none; }
}
</style>
