import { instance } from "@/configs/request.config";
import router from "@/router";
import {BASE_URL, TOKEN} from "@/constants";
import usePayloadBuilder from "@/usePayloadBuilder";
import {AlertConfigurations} from "@/service/alerts.service";
import {Utility} from "@/utility";
import axios from "axios";
export async function requestLogin(username, password) {
    try {
        const response = await instance.post("auth", {username, password});
        if (response) {
            localStorage.setItem(TOKEN, response.data.data.token);
            localStorage.setItem('authUser', JSON.stringify(response.data.data.loggedUser));
            localStorage.setItem('expirationLimit', response.data.data.expiresAt);

            return response
        }
    } catch(error) {
        if (error) return error.response
        if (!error.response) console.error("Can't connect to the server");
        console.error("Something went wrong");
    }
}

export async function logoutUser() {
    try {
        localStorage.clear()
        await router.push({ name: 'login' });
    } catch(error) {
        console.error("Something went wrong");
    }
}

/**
 * Credits to CJ
 * general use of payloadbuilder of getAll
 * @param requestName
 * @param conditions
 * @param state
 * @param requestType
 * @returns {Promise<*|*[]>}
 */
export async function getAll(requestName, conditions = null, state = null, requestType = "list") {
  try {
    let payloadBuilder  = usePayloadBuilder(requestName, requestType)

    if (conditions) payloadBuilder.addPayload("conditions", conditions);

    let result = await payloadBuilder.execute({}, state);

    if (result.data.code) {
      AlertConfigurations.failedAlert("Failed to retrieve list!", "SERVER ERROR");
      return [];
    }

    return result.data.data.map(datum => Utility.camelCaseProperties(datum));
  } catch(ex) {
    AlertConfigurations.failedAlert("Failed to retrieve list!", "SERVER ERROR");
  }
}

/**
 * Credits to CJ
 * simplified use of payloadbuilder of get
 * @param requestName
 * @param conditions
 * @returns {Promise<{}|null>}
 */
export async function get(requestName, conditions){
  let result = await usePayloadBuilder(requestName, "get").addPayload("conditions", conditions).execute();

  if (result.data.code) {
    AlertConfigurations.failedAlert("Failed to retrieve list!", "SERVER ERROR");
    return null;
  }

  return Utility.camelCaseProperties(result.data.data);
}

/**
 * Credits to CJ
 * Simplified use of save of payloadbuilder
 * @param requestName
 * @param requestType
 * @param values
 * @param conditions
 * @returns {Promise<boolean>}
 */
export async function save(requestName, requestType, values, conditions = null, is_multipart = false) {
  let payload = usePayloadBuilder(requestName, requestType).addPayload("values", values);

  if (conditions) payload.addPayload("conditions", conditions);

  const config  = is_multipart ? { headers: {"Content-Type" : "multipart/form-data"}} : {};
  let result = await payload.execute(config);

  // if (result.data.code) {
  //   AlertConfigurations.failedAlert("Failed to save information!", "SERVER ERROR");
  //   console.error(result.data.msg)
  //   return result;
  // }

  // return result;
  if (requestType === "add") return result;
  else if (requestType === "update") return result;
}

export async function deleteRequest(requestName, conditions) {
  const result = await usePayloadBuilder(requestName, "delete")
    .addPayload("conditions", conditions)
    .execute();

  return result;
}


export async function downloadFile(pathURI, fileName) {
  //change to post
  return await axios.get(pathURI, {
    baseURL: BASE_URL,
    responseType: "blob"
  })
    .then(response => {
      const fileLink = document.createElement("a");
      fileLink.href= URL.createObjectURL(new Blob([response.data]));
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    })
    .catch(ex => {
      console.error(ex)
    })
}

export async function createURLFile(pathURI) {
  return await axios.get(pathURI, {
    baseURL: BASE_URL,
    responseType: "blob"
  })
    .then(response => {
      return URL.createObjectURL(new Blob([response.data]));
    })
    .catch(ex => {
      console.error(ex)
    })
}
