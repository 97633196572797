import {createRouter, createWebHistory} from 'vue-router'
import DashboardPage from '../views/DashboardPage.vue'
import NotFound from '../views/misc/NotFound.vue'
import Login from "../views/Login.vue";
import MainLayout from "../views/MainLayout.vue";
import {TOKEN} from "@/constants";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '',
    redirect: {name: 'login'},
    component: MainLayout,
    meta: {requiresAuth: true},
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Filler.vue'),
        redirect: {name: 'quickButtons'},
        children: [
          {
            path: 'quick-buttons',
            name: 'quickButtons',
            component: () => import('../views/dashboard/QuickButtonsPage.vue')
          },
          {
            path: 'transaction-widgets',
            name: 'transactionWidgets',
            component: () => import('../views/dashboard/TransactionWidgets.vue')
          },
          {
            path: 'quotation-sample',
            name: 'QuotationSample',
            component: () => import('../views/dashboard/QuotationSample.vue')
          }
        ]
      },
      {
        path: '/transactions',
        name: 'transactionsFiller',
        component: () => import('../views/Filler.vue'),
        children: [
          {
            path: '/transactions',
            name: 'transactions',
            redirect: {name: 'journalList'},
            component: () => import('../views/transactions/TransactionsPage.vue'),
          },
          {
            path: 'receivable',
            name: 'receivable',
            redirect: {name: 'receivableBilling'},
            component: () => import('../views/Filler.vue'),
            children: [
              {
                path: 'billing',
                name: 'receivableBilling',
                redirect: to => ({name: "receivableBillingList"}),
                children: [
                  {
                    path: "",
                    name: "receivableBillingList",
                    component: () => import("@/views/transactions/receivable/BillingList.vue")
                  },
                  {
                    path: ":id",
                    name: "receivableBillingDetails",
                    props: true,
                    component: () => import("@/views/transactions/receivable/BillingDetails.vue")
                  },
                  {
                    path: "new",
                    name: "receivableNewBilling",
                    props: true,
                    component: () => import("@/views/transactions/receivable/NewBilling.vue")
                  }
                ]
              },
              {
                path: 'collection',
                name: 'receivableCollection',
                redirect: to => ({name: "receivableCollectionList"}),
                children: [
                  {
                    path: "",
                    name: "receivableCollectionList",
                    component: () => import("@/views/transactions/receivable/CollectionList.vue")
                  },
                  {
                    path: ":id",
                    name: "receivableCollectionDetails",
                    props: true,
                    component: () => import("@/views/transactions/receivable/CollectionDetails.vue")
                  },
                  {
                    path: "new",
                    name: "receivableNewCollection",
                    props: true,
                    component: () => import("@/views/transactions/receivable/NewCollection.vue")
                  }
                ]
              },
              {
                path: 'sales-receipt',
                name: 'salesReceipt',
                redirect: {name: 'salesReceiptList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'salesReceiptList',
                    props: {header: 'Sales Receipt'},
                    component: () => import('@/views/transactions/SalesList.vue')
                  },
                  {
                    path: 'create',
                    name: 'salesReceiptForm',
                    props: {header: 'Sales Receipt'},
                    component: () => import('@/views/transactions/SalesForm.vue')
                  }
                ]
              },
            ]
          },
          {
            path: 'payable',
            name: 'payable',
            redirect: {name: 'quotations'},
            component: () => import('@/views/Filler.vue'),
            children:[
              {
                path: 'purchase',
                name: 'payablePurchases',
                redirect: {name: 'payablePurchasesList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'payablePurchasesList',
                    component: () => import('@/views/transactions/payable/PurchaseList.vue')
                  },
                  {
                    path: 'new',
                    name: 'payableNewPurchase',
                    component: () => import('@/views/transactions/payable/PurchaseList.vue')
                  },
                ]
              },
              {
                path: 'payment',
                name: 'payablePayment',
                redirect: {name: 'payablePaymentList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'payablePaymentList',
                    component: () => import('@/views/transactions/payable/PaymentList.vue')
                  },
                  {
                    path: 'new',
                    name: 'payableNewPayment',
                    component: () => import('@/views/transactions/payable/PaymentList.vue')
                  },
                ]
              },
              {
                path: 'requisitions',
                name: 'requisitions',
                redirect: {name: 'requisitionList'},
                props: {header: 'Requisitions'},
                component: () => import('../views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'requisitionList',
                    component: () => import('@/views/transactions/Requisitions.vue')
                  },
                  {
                    path: ":transNo",
                    name: "requisitionForm",
                    props: true,
                    component: () => import("@/views/transactions/PurchasesFormList.vue")
                  }
                ]
              },
              {
                path: 'purchase-invoice',
                name: 'purchaseInvoice',
                props: {header: 'Purchase Invoice'},
                redirect: {name: 'purchaseInvoiceList'},
                component: () => import('../views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'purchaseInvoiceList',
                    component: () => import('@/views/transactions/PurchaseInvoice.vue')
                  },
                  {
                    path: ":transNo",
                    name: "purchaseInvoiceForm",
                    props: true,
                    component: () => import("@/views/transactions/PurchasesFormList.vue")
                  }
                ]
              },
              {
                path: 'purchase-order',
                name: 'purchaseOrder',
                props: {header: 'Purchase Order'},
                redirect: {name: 'purchaseOrderList'},
                component: () => import('../views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'purchaseOrderList',
                    component: () => import('../views/transactions/PurchaseOrder.vue')
                  },
                  {
                    path: ":transNo",
                    name: "purchaseOrderForm",
                    props: true,
                    component: () => import("@/views/transactions/PurchasesFormList.vue")
                  }
                ]
              }
            ]
          },
          {
            path: 'banks',
            name: 'banks',
            redirect: {name: 'bankDepositList'},
            component: () => import('@/views/Filler.vue'),
            children:[
              {
                path: 'deposits',
                name: 'bankDeposits',
                redirect: {name: 'bankDepositList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'bankDepositList',
                    component: () => import('@/views/transactions/banks/DepositList.vue')
                  },
                  {
                    path: 'new',
                    name: 'bankNewDeposit',
                    component: () => import('@/views/transactions/payable/PurchaseList.vue')
                  },
                ]
              },
              {
                path: 'withdrawals',
                name: 'bankWithdrawals',
                redirect: {name: 'bankWithdrawalList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'bankWithdrawalList',
                    component: () => import('@/views/transactions/banks/WithdrawalList.vue')
                  },
                  {
                    path: 'new',
                    name: 'bankNewWithdrawal',
                    component: () => import('@/views/transactions/payable/PurchaseList.vue')
                  },
                ]
              },
              {
                path: 'reconciliation',
                name: 'bankReconciliation',
                redirect: {name: 'bankReconciliationList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'bankReconciliationList',
                    component: () => import('@/views/transactions/banks/ReconciliationList.vue')
                  },
                  {
                    path: 'new',
                    name: 'bankNewReconciliation',
                    component: () => import('@/views/transactions/payable/PurchaseList.vue')
                  },
                ]
              },
            ]
          },
          {
            path: 'sales',
            name: 'sales',
            redirect: {name: 'billingInvoice'},
            component: () => import('../views/Filler.vue'),
            children: [
              {
                path: 'billing-invoice',
                name: 'billingInvoice',
                redirect: {name: 'billingInvoiceList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'billingInvoiceList',
                    props: {header: 'Billing Invoice'},
                    component: () => import('@/views/transactions/SalesList.vue')
                  },
                  {
                    path: 'create',
                    name: 'billingInvoiceForm',
                    props: {header: 'Billing Invoice '},
                    component: () => import('@/views/transactions/SalesForm.vue')
                  }
                ]
              },
              {
                path: 'sales-receipt',
                name: 'salesReceipt',
                redirect: {name: 'salesReceiptList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'salesReceiptList',
                    props: {header: 'Sales Receipt'},
                    component: () => import('@/views/transactions/SalesList.vue')
                  },
                  {
                    path: 'create',
                    name: 'salesReceiptForm',
                    props: {header: 'Sales Receipt'},
                    component: () => import('@/views/transactions/SalesForm.vue')
                  }
                ]
              },
            ]
          },
          {
            path: 'purchases',
            name: 'purchases',
            redirect: {name: 'quotations'},
            component: () => import('@/views/Filler.vue'),
            children:[
              {
                path: 'quotations',
                name: 'quotations',
                props: {header: 'Quotations'},
                redirect: {name: 'quotationList'},
                component: () => import('@/views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'quotationList',
                    component: () => import('@/views/transactions/Quotations.vue')
                  },
                  {
                    path: ":transNo",
                    name: "quotationForm",
                    props: true,
                    component: () => import("@/views/transactions/PurchasesFormList.vue")
                  }
                ]
              },
              {
                path: 'requisitions',
                name: 'requisitions',
                redirect: {name: 'requisitionList'},
                props: {header: 'Requisitions'},
                component: () => import('../views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'requisitionList',
                    component: () => import('@/views/transactions/Requisitions.vue')
                  },
                  {
                    path: ":transNo",
                    name: "requisitionForm",
                    props: true,
                    component: () => import("@/views/transactions/PurchasesFormList.vue")
                  }
                ]
              },
              {
                path: 'purchase-invoice',
                name: 'purchaseInvoice',
                props: {header: 'Purchase Invoice'},
                redirect: {name: 'purchaseInvoiceList'},
                component: () => import('../views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'purchaseInvoiceList',
                    component: () => import('@/views/transactions/PurchaseInvoice.vue')
                  },
                  {
                    path: ":transNo",
                    name: "purchaseInvoiceForm",
                    props: true,
                    component: () => import("@/views/transactions/PurchasesFormList.vue")
                  }
                ]
              },
              {
                path: 'purchase-order',
                name: 'purchaseOrder',
                props: {header: 'Purchase Order'},
                redirect: {name: 'purchaseOrderList'},
                component: () => import('../views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'purchaseOrderList',
                    component: () => import('../views/transactions/PurchaseOrder.vue')
                  },
                  {
                    path: ":transNo",
                    name: "purchaseOrderForm",
                    props: true,
                    component: () => import("@/views/transactions/PurchasesFormList.vue")
                  }
                ]
              }
            ]
          },
          {
            path: 'disbursement',
            name: 'disbursement',
            redirect: {name: 'disbursementList'},
            component: () => import('@/views/Filler.vue'),
            children:[
              {
                path: '',
                name: 'disbursementList',
                component: () => import("@/views/transactions/vouchers/DisbursementList.vue"),
              },
              {
                path: 'new',
                name: 'disbursementNew',
                component: () => import("@/views/transactions/vouchers/DisbursementVoucher.vue"),
              },
              {
                path: ':code',
                name: 'disbursementDetails',
                props: true,
                component: () => import("@/views/transactions/vouchers/DisbursementVoucherDetails.vue"),
              },
            ]
          },
          {
            path: 'receipts',
            name: 'receipts',
            redirect: {name: 'receiptList'},
            component: () => import('@/views/Filler.vue'),
            children:[
              {
                path: '',
                name: 'receiptList',
                component: () => import("@/views/transactions/vouchers/ReceiptList.vue"),
              },
              {
                path: 'new',
                name: 'receiptNew',
                component: () => import("@/views/transactions/vouchers/ReceiptVoucher.vue"),
              },
              {
                path: ':code',
                name: 'receiptDetails',
                props: true,
                component: () => import("@/views/transactions/vouchers/ReceiptVoucherDetails.vue"),
              },
            ]
          },
          {
            path: 'journals',
            name: 'journals',
            redirect: {name: 'journalList'},
            component: () => import('@/views/Filler.vue'),
            children:[
              {
                path: '',
                name: 'journalList',
                component: () => import("@/views/transactions/vouchers/JournalList.vue"),
              },
              {
                path: 'new',
                name: 'journalNew',
                component: () => import("@/views/transactions/vouchers/JournalVoucher.vue"),
              },
              {
                path: ':code',
                name: 'journalDetails',
                props: true,
                component: () => import("@/views/transactions/vouchers/JournalVoucherDetails.vue"),
              },
            ]
          },
          {
            path: 'prepaid-expenses',
            name: 'prepaidExpenses',
            redirect: {name: 'prepaidExpensesList'},
            component: () => import('@/views/Filler.vue'),
            children: [
              {
                path: '',
                name: 'prepaidExpensesList',
                component: () => import('@/views/transactions/prepaid/PrepaidExpensesList.vue')
              },
              {
                path: 'new',
                name: 'prepaidExpensesNew',
                component: () => import('@/views/transactions/prepaid/PrepaidExpenses.vue')
              },
              {
                path: ':code',
                name: 'prepaidExpensesDetails',
                component: () => import('@/views/transactions/prepaid/PrepaidExpenseDetails.vue')
              }
            ]
          },
          {
            path: 'cash-advances',
            name: 'cashAdvances',
            redirect: {name: 'cashAdvancesList'},
            component: () => import('@/views/Filler.vue'),
            children: [
              {
                path: '',
                name: 'cashAdvancesList',
                component: () => import('@/views/transactions/advances/CashAdvancesList.vue')
              },
              {
                path: 'new',
                name: 'cashAdvancesNew',
                component: () => import('@/views/transactions/advances/CashAdvancesNew.vue')
              },
              {
                path: ':code',
                props: true,
                name: 'cashAdvancesDetails',
                component: () => import('@/views/transactions/advances/CashAdvancesDetails.vue')
              }
            ]
          },
          {
            path: ':voucher',
            name: 'debitCreditVoucherFiller',
            redirect: to => ({ name: "debitCreditVoucherList", params: {voucher: to.params.voucher}}),
            component: () => import("@/views/Filler.vue"),
            props: true,
            children: [
              {
                path: "list",
                name: "debitCreditVoucherList",
                component: () => import("@/views/transactions/DebitCreditVoucherListPage.vue"),
                props: route => {
                  if (route.params.voucher !== 'cash-check-vouchers' && route.params.voucher !== 'payment-receipt-vouchers' && route.params.voucher !== 'sales-invoice' && route.params.voucher !== 'transaction-billing') {
                    router.replace({name: "Page404"})
                  }

                  let header = "";

                  if (route.params.voucher === 'cash-check-vouchers') {
                    header = "Disbursement Voucher";
                  } else if (route.params.voucher === 'payment-receipt-vouchers') {
                    header = "Receipt Voucher";
                  } else if (route.params.voucher === 'sales-invoice') {
                    header = "Sales Receipt";
                  } else if (route.params.voucher === 'transaction-billing') {
                    header =  "Billing";
                  }

                  return {
                    header: header
                  }
                }
              },
              {
                path: "create",
                name: "addDebitCreditVoucher",
                component: () => import('@/views/transactions/DebitCreditVoucher.vue'),
                props: (route) => {
                  if (route.params.voucher !== 'cash-check-vouchers' && route.params.voucher !== 'payment-receipt-vouchers'  && route.params.voucher !== 'sales-invoice' && route.params.voucher !== 'transaction-billing') {
                    router.replace({name: "Page404"})
                  }

                  let header = "";
                  let transactionType = "";
                  let supplier = "";
                  let agent = "";

                  if (route.params.voucher === 'cash-check-vouchers') {
                    header = "Check Voucher";
                    transactionType = "Credit";
                    supplier = "Supplier";
                    agent = "Purchaser";
                  } else if (route.params.voucher === 'payment-receipt-vouchers') {
                    header = "Receipt Voucher";
                    transactionType = "Debit";
                    supplier = "Customer";
                    agent = "Agent";
                  } else if (route.params.voucher === 'transaction-billing') {
                    header = "Billing";
                    transactionType = "Billing";
                    supplier = "Customer";
                    agent = "Agent";
                  } else if (route.params.voucher === 'sales-invoice') {
                    header = "Sales";
                    transactionType = "Sales";
                    supplier = "Customer";
                    agent = "Agent";
                  }

                  return {
                    header: header,
                    transactionType: transactionType,
                    supplier: supplier,
                    agent: agent
                  }
                }
              }
            ],
          },
          {
            path: 'journal-vouchers',
            name: 'journalVoucherfiller',
            redirect: to => ({name: "journalVoucherList"}),
            component: () => import('../views/Filler.vue'),
            children: [
              {
                path: "list",
                name: "journalVoucherList",
                component: () => import("@/views/transactions/JournalVoucherList.vue"),
              },
              {
                path: "create",
                name: "addJournalVoucher",
                component: () => import("@/views/transactions/JournalVoucherPage.vue")
              }
            ]
          },
          {
            path: "bank-reconciliations",
            name: "bankReconFiller",
            redirect: to => ({name: "bankReconList"}),
            component: () => import("@/views/Filler.vue"),
            children: [
              {
                path: "list",
                name: "bankReconList",
                component: () => import("@/views/transactions/BankReconciliationList.vue")
              },
              {
                path: "create",
                name: "addBankRecon",
                component: () => import ("@/views/transactions/BankReconciliationPage.vue")
              }
            ]
          },
          {
            path: 'transaction-postings',
            name: 'transactionPostingFiller',
            component: () => import('../views/Filler.vue'),
            redirect: to => ({name: "transactionPostingList"}),
            children: [
              {
                path: "list",
                name: "transactionPostingList",
                component: () => import("@/views/transactions/TransactionPostingList.vue")
              },
              {
                path: "create",
                name: "addTransactionPosting",
                component: () => import ("@/views/transactions/TransactionPostingPage.vue")
              }
            ]
          },
          {
            path: 'edit-particulars',
            name: 'editParticularsFiller',
            redirect: to => ({name: "editParticularsList"}),
            component: () => import('../views/Filler.vue'),
            children: [
              {
                path: "list",
                name: "editParticularsList",
                component: () => import("@/views/transactions/EditParticularsList.vue")
              },
              {
                path: "create",
                name: "editParticularForm",
                component: () => import ("@/views/transactions/EditParticularsPage.vue")
              }
            ]
          }
        ]
      },
      {
        path: '/management',
        name: 'management',
        component: () => import('../views/management/ManagementPage.vue'),
        redirect: {name: 'fixAssetsAndInventoryFiller'},
        children: [
          {
            path: 'fix-asset-and-inventories',
            name: 'fixAssetsAndInventoryFiller',
            redirect: {name: 'fixAssetsAndInventoryList'},
            component: () => import('../views/Filler.vue'),
            children: [
              {
                path: 'list',
                name: 'fixAssetsAndInventoryList',
                component: () => import('../views/management/FixedAssetsInventoryPage.vue')
              },
              {
                path: "create",
                name: "fixAssetsAndInventoryForm",
                component: () => import("@/components/forms/FixAssetsAndInventoryForm.vue")
              },
              {
                path: ":id",
                name: 'editAssetAndInventoryDetails',
                props: true,
                component: () => import('@/components/details/FixAssetsAndInventoryDetails.vue')
              },
            ]
          },
          {
            path: 'accounts-payable',
            name: 'accountsPayableFiller',
            redirect: to => ({name: "accountsPayableList"}),
            component: () => import('../views/Filler.vue'),
            children: [
              {
                path: "list",
                name: 'accountsPayableList',
                component: () => import('@/views/management/AccountsPayablePage.vue')
              },
              {
                path: ":id",
                name: 'accountsPayableDetails',
                props: true,
                component: () => import('@/components/details/AccountsPayableDetails.vue')
              },
            ]
          },
          {
            path: '/accounts-receivable',
            name: 'accountsReceivableFiller',
            component: () => import('../views/Filler.vue'),
            children: [
              {
                path: "list",
                name: 'accountsReceivableList',
                component: () => import('../views/management/AccountsReceivablePage.vue')
              },
              {
                path: ":id",
                name: "accountsReceivableDetails",
                props: true,
                component: () => import("@/components/details/AccountsReceivableDetails.vue")
              }
            ]
          }
        ]
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('../views/reports/ReportsPage.vue'),
        redirect: {name: 'journalSummary'},
        children: [
          {
            path: 'transaction-reports',
            name: 'transactionsReport',
            component: () => import('../views/reports/ReportsTransactionsPage.vue'),
            redirect: {name: 'cashCheckVoucherReport'},
            children: [
              {
                path: 'cash-check-voucher-report',
                name: 'cashCheckVoucherReport',
                component: () => import('../views/reports/CashCheckVoucherReportPage.vue')
              },
              {
                path: 'payment-receipts-voucher-report',
                name: 'paymentReceiptsVoucherReport',
                component: () => import('../views/reports/PaymentReceiptsReportPage.vue')
              },
              {
                path: 'journal-voucher-report',
                name: 'journalVoucherReport',
                component: () => import('../views/reports/JournalVouchersReportPage.vue')
              }
            ]
          },
          {
            path: 'journal-summary',
            name: 'journalSummary',
            component: () => import('../views/reports/JournalSummary.vue')
          },
          {
            path: 'ledger-summary',
            name: 'ledgerSummary',
            component: () => import('../views/reports/LedgerSummary.vue')
          },
          {
            path: 'trial-balance',
            name: 'trialBalance',
            component: () => import('../views/reports/TrialBalancePage.vue')
          },
          {
            path: 'financial-statements',
            name: 'financialStatements',
            component: () => import('../views/reports/FinancialStatementsPage.vue'),
            redirect: {name: 'balanceSheet'},
            children: [
              {
                path: 'balance-sheet',
                name: 'balanceSheet',
                component: () => import('../views/reports/BalanceSheetPage.vue')
              },
              {
                path: 'income-statement',
                name: 'incomeStatement',
                component: () => import('../views/reports/IncomeStatementPage.vue')
              },
              {
                path: 'statement-of-accounts',
                name: 'statementOfAccountsReport',
                component: () => import('../views/reports/StatementOfAccountsPage.vue')
              },
              {
                path: 'trial-balance-old',
                name: 'trialBalance-old',
                component: () => import('../views/reports/TrialBalancePage.vue')
              },
            ]
          },
          {
            path: 'account-details',
            name: 'accountDetails',
            component: () => import('../views/reports/AccountDetailsPage.vue')
          },
          {
            path: 'master-file',
            name: 'masterFile',
            redirect: {name: 'supplierSummary'},
            component: () => import('../views/reports/MasterFilePage.vue'),
            children: [
              {
                path: 'supplier-summary',
                name: 'supplierSummaryFiller',
                component: () => import('../views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'supplierSummary',
                    component: () => import('../views/reports/SupplierSummaryPage.vue')
                  },
                  {
                    path: ':code',
                    name: 'supplierTransactionDetail',
                    props: {header: 'Customer'},
                    component: () => import('../components/details/CustomerSupplierTransactionDetails.vue')
                  }
                ]
              },
              {
                path: 'customer-summary',
                name: 'customerSummaryFiller',
                component: () => import('../views/Filler.vue'),
                children: [
                  {
                    path: '',
                    name: 'customerSummary',
                    component: () => import('../views/reports/CustomerSummaryPage.vue')
                  },
                  {
                    path: ':code',
                    name: 'customerTransactionDetail',
                    props: {header: 'Customer'},
                    component: () => import('../components/details/CustomerSupplierTransactionDetails.vue')
                  }
                ]
              }
            ]
          },
          {
            path: 'budget-vs-actual',
            name: 'budgetVsActual',
            component: () => import('../views/reports/BudgetVsActualPage.vue')
          },
        ]
      },
      {
        path: '/monitoring',
        name: 'monitoring',
        component: () => import('../views/monitoring/MonitoringPage.vue'),
        redirect: {name: 'vofReceiving'},
        children: [
          {
            path: 'vof-receiving',
            name: 'vofReceivingFiller',
            component: () => import('../views/Filler.vue'),
            redirect: to => ({name: "vofReceiving"}),
            children: [
              {
                path: "list",
                name: "vofReceiving",
                component: () => import("@/views/monitoring/vofReceivingPage.vue")
              },
              {
                path: ":transNo",
                name: "vofTransactionDetails",
                props: true,
                component: () => import("@/components/details/vofTransactionDetails.vue")
              }
            ]
          },
          {
            path: 'vof-adjustment',
            name: 'vofAdjustment',
            component: () => import('../views/monitoring/vofAdjustmentPage.vue')
          },
          {
            path: 'posting',
            name: 'posting',
            component: () => import('../views/monitoring/Posting.vue')
          }
        ]
      },
      {
        path: 'setup',
        name: 'setup',
        component: () => import('../views/SetupPage.vue'),
        redirect: {name: 'commonSetup'},
        children: [
          {
            path: 'system',
            name: 'systemSetup',
            component: () => import('../views/setup/system/SystemSetupPage.vue'),
            redirect: to => {
              return {name: 'suppliers', params: {supplier: "customers"}}
            },
            children: [
              {
                path: ':supplier',
                name: 'suppliers',
                component: () => import('../views/setup/system/SuppliersPage.vue'),
                props: (route) => {
                  if (route.params.supplier !== 'suppliers' && route.params.supplier !== 'customers') {
                    router.replace({name: "Page404"})
                  }

                  let header = "";

                  if (route.params.supplier === 'suppliers') {
                    header = "Suppliers";
                  } else if (route.params.supplier === 'customers') {
                    header = "Customers";
                  }

                  return {
                    header: header,
                  }
                }
              },
              {
                path: 'agents',
                name: 'agents',
                component: () => import('../views/setup/system/AgentsPage.vue'),
                props: {header: 'Agents'}
              },
              {
                path: 'purchasers',
                name: 'purchasers',
                component: () => import('../views/setup/system/PurchasersPage.vue'),
                props: {header: 'Purchasers'}
              },
              {
                path: 'posting-settings',
                name: 'postingSettings',
                component: () => import('../views/setup/system/PostingSettingsPage.vue'),
              },
              {
                path: 'administrative-settings',
                name: 'administrativeSettings',
                component: () => import('../views/setup/system/AdministrativeSettingsPage.vue'),
              },
              {
                path: 'operation-settings',
                name: 'operationSettings',
                component: () => import('../views/setup/system/OperationSettingsPage.vue'),
              },
              {
                path: 'taxes',
                name: 'taxes',
                component: () => import('../views/setup/system/TaxesPage.vue'),
              },
              {
                path: 'business-types',
                name: 'businessTypes',
                component: () => import('../views/setup/system/BusinessTypesPage.vue'),
              },
              {
                path: 'payment-terms',
                name: 'paymentTerms',
                component: () => import('../views/setup/system/PaymentTerms.vue'),
              },
              {
                path: 'customer-group',
                name: 'customerGroup',
                component: () => import('../views/setup/system/CustomerGroupPage.vue'),
              },
              {
                path: 'billing-setup',
                name: 'billingSetup',
                component: () => import('../views/setup/system/SubDetailsBillingSetupPage.vue'),
              },
              {
                path: 'template-setup',
                name: 'templateSetup',
                component: () => import('../views/setup/system/TemplateSetupPage.vue'),
              }
            ]
          },
          {
            path: 'common',
            name: 'commonSetup',
            component: () => import('../views/setup/common/CommonSetupPage.vue'),
            redirect: {name: 'users'},
            children: [
              {
                path: 'users',
                name: 'users',
                component: () => import('../views/setup/common/UsersPage.vue')
              },
              {
                path: 'access-and-restrictions',
                name: 'accessAndRestrictions',
                component: () => import('../views/setup/common/AccessAndRestrictionsPage.vue'),
              },
              {
                path: 'user-profile-and-policies',
                name: 'userProfileAndPolicies',
                component: () => import('../views/setup/common/UserProfileAndPoliciesPage.vue'),
              },
              {
                path: 'overrides',
                name: 'overrides',
                component: () => import('../views/setup/common/OverridesPage.vue'),
              },
              {
                path: 'transaction-logs',
                name: 'transactionLogs',
                component: () => import('../views/setup/common/TransactionLogsPage.vue'),
              },
              {
                path: 'history-logs',
                name: 'historyLogs',
                component: () => import('../views/setup/common/HistoryLogsPage.vue'),
              },
              {
                path: 'email-and-notification',
                name: 'emailAndNotification',
                component: () => import('../views/setup/common/EmailAndNotificationPage.vue'),
              },
            ]
          },
          {
            path: 'accounting',
            name: 'accountingSetup',
            component: () => import('../views/setup/accounting/AccountingSetupPage.vue'),
            redirect: {name: 'chartOfAccounts'},
            children: [
              {
                path: 'chart-of-accounts',
                name: 'chartOfAccounts',
                component: () => import('../views/setup/accounting/ChartOfAccountsPage.vue'),
              },
              {
                path: 'subsidiary-ledger',
                name: 'subsidiaryLedger',
                component: () => import('../views/setup/accounting/SubsidiaryLedgerPage.vue'),
              },
              {
                path: 'transaction-settings',
                name: 'transactionSettings',
                component: () => import('../views/Filler.vue'),
                redirect: {name: 'transactionSettingsList' },
                children: [
                  {
                    path: 'list',
                    name: 'transactionSettingsList',
                    component: () => import('../views/setup/accounting/TransactionSettingsPage.vue')
                  },
                  {
                    path: 'create',
                    name: 'transactionSettingsForm',
                    component: () => import('../views/setup/accounting/TransactionSettingsForm.vue'),
                  }
                ]
              },
              {
                path: 'budget',
                name: 'budget',
                component: () => import('../views/setup/accounting/BudgetPage.vue'),
              },
              {
                path: 'beginning-balances',
                name: 'beginningBalances',
                component: () => import('../views/setup/accounting/BeginningBalancesPage.vue'),
              },
              {
                path: 'statement-of-accounts',
                name: 'statementOfAccounts',
                component: () => import('../views/setup/accounting/StatementOfAccountsPage.vue'),
              },
              {
                path: 'account-mapping',
                name: 'accountMapping',
                component: () => import('../views/setup/accounting/SubsidiaryLedgerPage.vue'),
              },
              {
                path: 'report-templates',
                name: 'reportTemplates',
                component: () => import('../views/setup/accounting/ReportTemplatesPage.vue'),
              },
            ]
          },
          {
            path: '/miscellaneous',
            name: 'miscellaneous',
            component: () => import('../views/setup/misc/MiscellaneousSetupPage.vue'),
            redirect: {name: 'apiSetup'},
            children: [
              {
                path: 'api-setup',
                name: 'apiSetup',
                component: () => import('../views/setup/misc/ApiSetupPage.vue')
              },
              {
                path: 'company-setup',
                name: 'companySetup',
                component: () => import('../views/setup/misc/CompanySetupPage.vue')
              }
            ]
          }
        ]
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page404',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    let expirationDate = Date.parse(localStorage.getItem("expirationLimit"));
    if (!localStorage.getItem(TOKEN) && to.name !== "login") return next({name: "login"})
    else next()

    if (Date.now() > expirationDate) {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem("authUser");
      localStorage.removeItem("expirationLimit");
      return next({name: "login"})
    }

  } else {
    if(localStorage.getItem(TOKEN) && to.name === 'login') {
      next({name: 'dashboard'})
    } else {
      next()
    }
  }
});

export default router
