import {validateEmail, validateFaxNumber, validateName, validateNumber, validateTinNumber} from "@/composables/regexTesting";

export function emailValidator(value) {
  if (!validateEmail(value)) {
    return {
      status: false,
      message: "This email is not valid. (ex. example@gmail.com)"
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function mobileNumberValidator(value) {
  if (!validateNumber(value)) {
    return {
      status: false,
      message: "Contact number format is invalid (ex. 09123456789 or +639123456789)."
    }
  }

  return {
    status: true,
    message: ""
  }
}


export function faxNumberValidator(value) {
  if (!validateFaxNumber(value)) {
    return {
      status: false,
      message: "Fax number format is invalid (ex. 123-456-7890)."
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function tinNumberValidator(value) {
  if (!validateTinNumber(value)) {
    return {
      status: false,
      message: "Tin number format is invalid (ex. 123-456-412-340 or 123456412340)."
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function zipCodeValidator(value) {
  const zipCodeRegex = new RegExp(/(^\d{4})$/g)
  if (!zipCodeRegex.test(value)) {
    return {
      status: false,
      message: "Zip Code is invalid. It must have four digits (ex. 1234)."
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function decimalValidator(value) {
  const decimalRegex = /^\d+(\,\d+)?(\.\d+)?$/g;
  if (! decimalRegex.test(value)) {
    return {
      status: false,
      message: "Please input valid number values"
    }
  } 

  return {
    status: true,
    message: ""
  }
}

export function currencyValidator(value) {
  const currencyRegex = /^([0-9]+(\.[0-9]+)?|\.[0-9]+)$/g
  if(!currencyRegex.test(value)) {
    return {
      status: false,
      message: "Please input valid amount."
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function currencyFormatValidator(value) {
  const currencyRegex = /^([0-9]{1,3}(,[0-9]{3})+(\.[0-9]+)?|\.[0-9]+)$/g
  const otherCurrency = /^([0-9]+(\.[0-9]+)?|\.[0-9]+)$/g

  if(!currencyRegex.test(value) && !otherCurrency.test(value)) {
    return {
      status: false,
      message: "Please input valid amount."
    }
  }

  let rounded = value.split('.')
  if(rounded[1]?.trim().length > 2) {
    return {
      status: false,
      message: "Amount should only have two decimal places."
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function passwordValidator(value) {
  if(value.length < 8) {
    return {
      status: false,
      message: "Password must have atleast 8 characters."
    }
  }

    return {
      status: true,
      message: ""
    }
}

export function nameValidator(value) {
  if(!validateName(value)) {
    if((/^[^a-zA-Z0-9]/g).test(value)) {
      return {
        status: false,
        message: "This field must not start with a special character."
      }
    }
    return {
      status: false,
      message: "This field must not contain any number."
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function operationsCodeValidator(value) {
  if(value.length < 3) {
    return {
      status: false,
      message: "Code must atleast have three characters."
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function minNumValidator(value) {
  if(value < 1) {
    return {
      status: false,
      message: "Please input a number greater than 0."
    }
  }

  return {
    status: true,
    message: ""
  }
}

export function characterLimitValidator(value) {
  if(value.length > 160) {
    return {
      status: false,
      message: "Please limit to 160 characters."
    }
  }

  return {
    status: true,
    message: ""
  }
}
