import Swal from "sweetalert2";
import {logoutUser} from "@/request";

export const AlertConfigurations = {
    confirmLogOutAlert (){
        return Swal.fire({
            title: 'Logout?',
            text: 'Are you sure you want to logout?',
            confirmButtonText: 'Logout',
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: 'var(--app-dark-gray)',
            showCancelButton: true,
            }).then((logout) => {
                if(logout.isConfirmed){
                    logoutUser()
                }
            })
        },
    successAlert(text, action) {
        return Swal.fire({
            title: action,
            icon: `success`,
            text: text,
            showConfirmButton: false,
            timer: 3000
        })
    },
    async confirmAlert(text, action) {
      return await Swal.fire({
        title: action,
        icon: "question",
        text: text,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#0d6efd',
        cancelButtonColor: 'var(--app-dark-gray)',
        showCancelButton: true,
      }).then(response => response.isConfirmed)
    },
    async confirmDeleteAlert(text, action) {
        return await Swal.fire({
            title: action,
            icon: "warning",
            iconColor: '#FF4343',
            text: text,
            confirmButtonText: 'Proceed to delete',
            confirmButtonColor: '#FF4343',
            cancelButtonColor: 'var(--app-dark-gray)',
            showCancelButton: true,
        }).then(response => response.isConfirmed)
    },
    async confirmLeaveFormAlert() {
        return await Swal.fire({
            title: 'You have unsaved changes!',
            icon: 'warning',
            text: 'You will lose all your changes. Are you sure you wish to leave?',
            confirmButtonText: 'Proceed to leave',
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: 'var(--app-dark-gray)',
            showCancelButton: true,
        }).then(response => response.isConfirmed)
    },
    failedAlert (text, action) {
        return Swal.fire({
            title: action,
            icon: `error`,
            text: text,
            timer: 3000
        })
    },
    loadingAlert (text, action) {
        return Swal.fire({
            title: action,
            iconHtml: "<div class='spinner-border fs-2 text-secondary' style='width: 5rem; height: 5rem;' role='status'></div>",
            showConfirmButton: false,
            text: text,
            timer: 800,
            allowOutsideClick: false,
            allowEscapeKey: false
        })
    },
    infoAlert(text, action) {
        return Swal.fire({
            title: action,
            icon: 'warning',
            text: text,
            timer: 3000
        })
    },
    toastNotification (text, action, icon) {
        return Swal.fire({
            toast: true,
            title: action,
            icon: icon,
            text: text,
            timer: 3000,
            showConfirmButton: false,
            position: 'top-right'
        })
    }
}