<script setup>

</script>

<template>
<aside class="side-nav py-4 px-1 overflow-auto">
  <div class="sidenav nav px-1 d-flex flex-column gap-3">
    <slot name="sidebarContent"></slot>
  </div>
</aside>

</template>

<style scoped>
    a {
        color: black;
        text-decoration: none;
    }

    ::-webkit-scrollbar {
        width: 0.5em;
        background-color: white;
      }

      /* Make scrollbar visible when needed */
      ::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
      }

      /* Make scrollbar track visible when needed */
      ::-webkit-scrollbar-track {
        background-color: var(--sidebar-bg);
      }
</style>