<script setup>
import Loading from "@/components/base/Loading.vue";
import {requestLogin} from "@/request";
import { useRouter} from "vue-router";
import {computed, reactive, ref} from "vue";
import {useModuleStatesStore} from "@/stores/useModuleStatesStore";
import {AlertConfigurations} from "@/service/alerts.service";
import InvalidMessage from "@/components/misc/InvalidMessage.vue";
import InPageAlert from "@/components/base/InPageAlert.vue";
import useValidator from "@/composables/useValidator";
import {passwordValidator} from "@/validations";
import {ERROR_FIELD} from "@/constants";

const moduleState = useModuleStatesStore()
const router = useRouter();
const errorMessage = ref('Incorrect username and/or password.')
const credentials = reactive({
  username: '',
  password: ''
})

const isInvalidCredentials = ref(false);
const handleLogin = async() => {
  // let username = "admin"
  // let password = "acctg@2023";

  moduleState.isLoading = true
  const response = await requestLogin(credentials.username, credentials.password);
  moduleState.isLoading = false

  if(response.status === 500) {
    await AlertConfigurations.failedAlert('Internal Server Error', 'Server Error')
    return
  }
  if (response.status === 200){
    await router.push({ name: 'dashboard' });
    await AlertConfigurations.successAlert('Successfully Logged In', 'Logged In')
  }
  else {
    if(response.data.msg === "Error") errorMessage.value = `Cannot sign in. ${response.data.data.response}. Please contact the system administrator.`
    else errorMessage.value = 'Incorrect username and/or password.'
    isInvalidCredentials.value = true;
    setTimeout(() => {
      isInvalidCredentials.value = false;
    }, 5000)
    // await AlertConfigurations.failedAlert('You are not authorized to access this service.', 'Unauthorized')
  }
}
const icon = ref('fa-solid fa-eye-slash')
const showPassword = () => {
  let passwordInput = document.getElementById('password')
  if(passwordInput.type === 'password') {
    passwordInput.type='text'
    icon.value = 'fa-solid fa-eye'
  } else {
    passwordInput.type='password'
    icon.value = 'fa-solid fa-eye-slash'
  }
}

const credentialsValidation = useValidator(credentials, {
  username: {required: true},
  password: {required: true, validations: [passwordValidator]}
})
const {
  validationObject: credentialValidationObject,
  validator: validateCredential
} = credentialsValidation

const validate = computed(() => {
  if(credentials.username && (credentialsValidation.validationObject.value.password.fieldStatus === 1)) return false;
  return true;
})
</script>

<template>
<div class="login-page-container text-center">
  <div class="login-left-side">
    <div class="login-container">
      <div class="system-title">
        <h1 class="mainTitle">FUJITRANS</h1>
        <h3 class="subTitle">Logistics Philippines</h3>
        <h2 class="subSubTitle" style="font-size: 1.9em;">ACCOUNTING SYSTEM</h2>
      </div>
      <form @submit.prevent="handleLogin">
        <InPageAlert v-if="isInvalidCredentials" type="alert-danger alert-sm" style="padding: 0 !important; margin: 1rem 0 .5rem 0;" :message="errorMessage" />
        <div class="username-container">
          <label for="username">Username</label>
          <input type="text" class="form-control" id="username" name="username" v-model="credentials.username"
                 @input="() => validateCredential('username')"
                 @blur="() => validateCredential('username')"
          />
          <InvalidMessage
              :is-visible="credentialValidationObject.username.fieldStatus === ERROR_FIELD"
              :message="credentialValidationObject.username.message"></InvalidMessage>
        </div>
        <div class="password-container">
          <label for="password">Password</label>
          <div class="pass-input-container">
            <input type="password" class="form-control" id="password" name="password" v-model="credentials.password"
                   @input="() => validateCredential('password')"
                   @blur="() => validateCredential('password')"/>
            <font-awesome-icon :icon="icon" class="passwordStateIcon" @click="showPassword"/>
          </div>
          <InvalidMessage
              :is-visible="credentialValidationObject.password.fieldStatus === ERROR_FIELD"
              :message="credentialValidationObject.password.message"></InvalidMessage>
        </div>
        <button class="btn btn-primary px-4 fs-5" :disabled="validate">Sign In</button>
      </form>
    </div>
  </div>
  <div class="login-right-side">
    <div class="rotated-container shadow-lg ">
    </div>
  </div>
</div>
  <Loading v-if="moduleState.isLoading" loading-message="Logging In..."/>
</template>

<style scoped>
.login-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.login-container {
  text-align: start;
  width: 100%;
}

.system-title { font-family: var(--app-secondary-font-family) !important; }
.mainTitle { font-size: 3.5rem; font-weight: 700; color: var(--brand-bg); line-height: 0.5; }
.subTitle { font-size: 2.2rem; }

.subSubTitle { color: var(--app-darker-gray); font-size: 2.5rem; font-weight: 600; }
label { font-size: 15px; }
input { padding: .5rem 1.2rem; border: 1px solid var(--app-dark-gray); }
.password-container, .username-container { margin: 1rem 0; }
.pass-input-container { position: relative; }
.passwordStateIcon {
  cursor: pointer;
  position: absolute;
  margin: 0 1rem;
  right: 0;
  bottom: 0;
  transform: translateY(-70%);
}
.login-left-side {
  display: flex;
  width: 95%;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}
.login-right-side {
  overflow: hidden;
  height: 100vh;
  width: 50%;
}
/* ATTRITION: Image by <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=544956">Gerd Altmann</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=544956">Pixabay</a>
Image by <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1881265">Gerd Altmann</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1881265">Pixabay</a>*/
.rotated-container {
  height: 150%;
  background-image: url("../assets/img/dollar-544956_1920.jpg");
  background-position: 70%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--app-main-theme);
}
@media (width >= 992px) {
  .rotated-container {
    background-position: 100% 10%;
    transform: rotate(20deg) translateX(30%) translateY(-20%);
  }
  .login-left-side {
    width: 70%;
    padding-left: 10rem;
  }
  .login-right-side { width: 70%; }
  .subSubTitle { line-height: 0.8; }
}

@media (width >= 1440px) {
  .login-left-side { width: 50%; padding-left: 15rem; }
  .login-container { padding-right: 5rem; }
}
</style>
