import axios from "axios";
import {API_URL, TOKEN} from "@/constants";
const instance = axios.create({
    baseURL: API_URL,
});

instance.interceptors.request.use((config) => {
    let accessToken = localStorage.getItem(TOKEN) ?? null
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.request.use((response) => {
    return Promise.resolve(response);
}, (error) => {
    return Promise.reject(error);
});

export { instance };
