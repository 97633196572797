<script setup>
import {ref, defineProps, computed} from 'vue'

const props = defineProps(["modalWidthSize"])

const modalWith = computed(() => {
  if (props.modalWidthSize === undefined) return "";
  return "modal-" + props.modalWidthSize;
})
</script>

<template>
<Teleport to="body">
    <div class="modal fade" id="main-modal" data-bs-backdrop="static">
        <div class="modal-dialog" :class="modalWith">
            <div class="modal-content">
                <div class="modal-header" style="background-color: rgba(37, 41, 33, 0.05)">
                    <slot name="modalHeader">Modal</slot>
                </div>
              <div class="modal-body">
                <slot name="modalContent">Content</slot>
              </div>
              <div class="modal-footer" style="background-color: rgba(37, 41, 33, 0.05)">
                <slot name="modalFooter">Footer</slot>
              </div>
            </div>
        </div>
    </div>
</Teleport>
</template>

<style scoped>
.modal-content {
  background-color: white;
}
</style>