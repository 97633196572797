<script setup>

</script>

<template>
  <div class="collapsed-child collapse accordion-collapse collapse-show pb-2">
    <slot></slot>
  </div>
</template>

<style scoped>

</style>